/*Team Slider overrides*/
.slick-prev, .slick-next{
  top: calc(50% - 47px) !important;
  z-index: 50;
  height: 40px !important;
  width: 40px !important;
  color:#0c0c0c !important;
}
.slick-prev:hover, .slick-next:hover{
  opacity:0.78 !important;
}
.slick-disabled:hover{
  opacity: 1 !important;
  cursor: unset !important;
}
.slick-next {
    right: -65px !important;
}
.slick-prev {
    left: -65px !important;
}
.slick-track {
    left: -15px !important;
    width:3394px !important;
}
.slick-list{
    overflow: hidden;
    padding-left:40px;
    padding-right: 100px;
}
.slick-prev::before,.slick-next::before{
  font-size:40px !important;
  opacity:1 !important;

  color:#0c0c0c !important;
}
.slick-prev.slick-disabled::before,.slick-next.slick-disabled::before{
  opacity:0.25 !important;
}
.slick-slide img{
  height: 246px !important;
  background-size:100% !important;
  background-position: center center;
  transition:background-size 0.3s ease-in-out;
}
@media only screen and (max-width:1205px){
  .slick-slide img{
    height: 205px !important;
  }
}
@media only screen and (max-width:955px){
  .slick-slide img{
    height: 180px !important;
  }
}
@media only screen and (max-width:855px){
  .slick-slide img{
    height: 170px !important;
  }
}
@media only screen and (max-width:765px){
  .slick-slide img{
    height: 166px !important;
  }
}
.slick-slide:hover > div div div img{
  background-size:115% !important;
}
.slick-slide{
  /*pt: { lg:4, md: 4, sm: 5, xs: 5 },*/
   margin-top: 0rem !important;
   border-radius: 12px !important;
   overflow: hidden;
   margin:10px;
   background-color:#F4F6F5 !important;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
   min-height: 500px;
}
.slick-slide h2{
  font-weight: 400;
    opacity: 0.8;
}
.slick-slide:last-child{
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
.slick-slide div:nth-child(1){
  display: block;
}
.slick-slider{
  margin-top:20px !important;
  margin-left:-20px;
  max-height:600px;
}
.slick-slider::after{
  content:"";
  width:20vw;
  height:100%;
  position: absolute;
  right:-5vw;
  top:0;
  background:linear-gradient(270deg,#fff,#fff,transparent,transparent,transparent);
  pointer-events: none;
}
.slick-slider::before{
  content:"";
  width:60px;
  height:100%;
  position: absolute;
  left:-30px;
  top:0;
  z-index: 1;
  background:linear-gradient(90deg,#fff,#fff,transparent);
  pointer-events: none;
}
.Store{
  overflow: hidden;
  min-height: 750px;
}