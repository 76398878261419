/* INITIALIZERS + DEFAULTS
 * ============================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800');

.Store body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.Store img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.Store h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}
#features img{
  max-width: 115px;
  mix-blend-mode: multiply;
}
#features p{
  max-width: 300px;
  margin:auto;
  color:#3c3c70;
  font-family: 'Poppins';
}
#features h4{
  color:#2c2c50;
}
.headerIcon{
  position:absolute;
  left:-55px;
  top:-25px;
  z-index:1;
  transform:'rotate(47deg)  scale(0.8)'
}

.Store h2 {
      
  margin: 0;
  margin-left:-2px;
  font-size: 2rem;
}
.Store h5{
  color:#1c1c1c !important;
}
/* BASE APP
 * ============================== */
.App__header {
  background-color: transparent;
  background-image: url('../../images/TheMinersSupplyCoLogo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  padding: 10px 10px;
}

.App__nav{
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 60px 20px;
  text-align: center;
}

.Product-wrapper {
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width:1025px){
  .Product-wrapper{
    height: 80vh;
    overflow: scroll;
    padding-bottom:50px;
    margin-top:0px;
  }
  .App__title {
    padding: 40px 20px;
    text-align: center;
  }
}
.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: #1c1c1c;
  cursor: pointer;
  color: white;
}
@media only screen and (max-width:1025px){
  .App__view-cart-wrapper{
    margin-top: 40px;
    margin-right:15px;
  }
  .App__header{
    background-position: 10% 0;
    border-bottom:2px solid #1c1c1c;
    padding-bottom:15px;
    padding-top:15px;
  }
}
.App__view-cart:hover {
  background: #1c1c1ca0 !important;
}
.Store .button {
  background-color: #1c1c1c;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.Store .button:hover,
.Store .button:focus {
  background-color: #2c2c2c;
}

.Store .button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.Store .login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.Store .login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0,0,0,0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  /*border-left: 1px solid #e5e5e5;*/
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  z-index: 1210;
  box-shadow:0px 0px 8px #1c1c1c;

}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
  padding-top: 0rem; 
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}
.Cart__close:hover{
  background-color:transparent;
}
.Shop__close{
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
  padding-top: 0rem; 
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}
.Shop__close:hover{
  background-color:transparent;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
  background-color:#1c1c1c;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
  color:white;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: white;
}

/* LINE ITEMS
 * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: #4E5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
  padding-top: 0rem; 
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
  background-color:transparent !important;
}

/* PRODUCTS
 * ============================== */
.Product {
  flex: 0 1 23%;
  min-width:200px;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 1;
  color: #0c0c0c !important;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.7;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 70%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

/* CUSTOMER AUTH
 * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.Store .error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}
